<template>
  <div ref="vosParent">
    <div class="sort-header">
      <div class="is-flex">
        <div class="category-title">
          Summary
        </div>
        <div class="voc-chart-toggle-container is-flex">
          <button class="average-rating" :class="[activeVoCView === 'grid' && 'active']" @click="updateActiveVoCView('grid')">Average Rating</button>
          <button class="review-volume" :class="[activeVoCView === 'list' && 'active']" @click="updateActiveVoCView('list')">Review Volume</button>
        </div>
      </div>
      <div class="voc-header">
        <global-sort-vue
          :selected="sort"
          :showSortBy="true"
          :options="sortOptions"
          @onChange="onChangeSelection" />
          <!-- <div v-if="activeVoCView === 'list'" class="toggle">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="14.403" viewBox="0 0 16 14.403" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M14.4 0a1.6 1.6 0 00-1.15 2.707L10.33 8.55a1.313 1.313 0 00-.194-.019 1.6 1.6 0 00-.6.119L7.264 6.101a1.6 1.6 0 10-2.994-.769 1.588 1.588 0 00.394 1.038l-2.779 4.86a1.463 1.463 0 00-.284-.028 1.623 1.623 0 101.206.563l2.776-4.86a1.5 1.5 0 00.885-.091l2.269 2.554a1.6 1.6 0 102.547-.338l2.922-5.848a1.313 1.313 0 00.194.019 1.6 1.6 0 000-3.2z" fill="#c2c7de"/></svg>
            <ToggleButtonVue
              :toggle="showTrendLine"
              label="Trend Line"
              @onChange="onChangeTrendLine" />
          </div> -->
        <!-- <view-selector /> -->
      </div>
    </div>
    <div>
      <div
        class="stat-container"
        v-show="!isNoData">
        <div class="category-container">
          <div class="main-card" v-if="activeVoCView === 'list'">
            <div class="custom-table-container-block">
              <div class="voc-row-card-container">
                <table>
                  <thead>
                    <th>Product</th>
                    <th>Reviews</th>
                    <th>Average Ratings</th>
                    <th>Recommendation</th>
                    <th>
                      <div class="voc-legends">
                        <div class="voc-volume-legend voc">
                          <ul>
                            <svg xmlns="http://www.w3.org/2000/svg" width="15" height="16" viewBox="0 0 15 16" v-bind:svg-inline="''" v-bind:class="'icons'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path fill="#c2c7de" d="M12 16V2h3v14zm-6 0V0h3v16zm-6 0V6h3v10z"/></svg>
                            <p>Review volume</p>
                          </ul>
                        </div>
                        <div class="voc-volume-legend voc">
                          <ul class="line-icon">
                            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="130.221 187.116 34 11.14" width="30" height="10" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><defs><path d="M161.22 191.69h-30" id="a"/><path d="M149.79 191.69c0 1.97-1.6 3.57-3.57 3.57-1.97 0-3.57-1.6-3.57-3.57 0-1.97 1.6-3.57 3.57-3.57 1.97 0 3.57 1.6 3.57 3.57z" id="b"/></defs><use xlink:href="#a" fill="#583fcc"/><use xlink:href="#a" fill-opacity="0" stroke="var(--avg-rating-symbol-line)"/><g><use xlink:href="#b" fill="var(--avg-rating-symbol-circle)"/></g></svg>
                            <p>Avg. rating</p>
                          </ul>
                        </div>
                      </div>
                    </th>
                  </thead>
                  <tbody>
                    <template
                    class="row-border"
                      v-for="(product, index) in sortedProducts"
                      :id="'id' + product.id">
                      <VocRowCard
                      :key="index"
                      :product="product"
                      :index="index + 1"
                      @noData="setNoData(true)"
                      @data="setNoData(false)" />
                    </template>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div class="voc-card-container" v-if="activeVoCView === 'grid'">
            <div
              v-for="(product, index) in sortedProducts"
              :key="index"
              :id="'id' + product.id"
              class="psudo-container">
              <VocCard :product="product" @noData="setNoData(true)" @data="setNoData(false)" />
            </div>
          </div>
        </div>
      </div>
      <NoDataContainerVue v-show="isNoData" class="mt-5" />
    </div>
  </div>
</template>

<script>
import VocCard from '@/components/vocSummary/VocCard.vue'
import VocRowCard from '@/components/vocSummary/VocRowCard.vue'
import NoDataContainerVue from '@/components/Common/NoDataContainer/NoDataContainer.vue'
import { metaTitle, analyticsEvents } from '../../constant/data'
import { mapState, mapActions } from 'vuex'
import GlobalSortVue from '../../components/GlobalSort/GlobalSort.vue'

/** This is the parent component/view for the **VocSummary** page. */
export default {
  name: 'VocSummary',
  metaInfo () {
    return {
      title: metaTitle.vocSummary
    }
  },
  components: {
    VocCard,
    NoDataContainerVue,
    GlobalSortVue,
    VocRowCard
  },
  data () {
    return {
      isNoData: false,
      showDisplayOption: true,
      showTrendLine: false
    }
  },
  computed: {
    ...mapState('filters', [
      'selectedProducts',
      'subCategories',
      'categoryList',
      'productList',
      'selectedSourceList',
      'ActiveVocTimePeriod',
      'selectedIndustries',
      'selectedCompanies',
      'selectedCategories'
    ]),
    ...mapState('vocsummary', [
      'reviewVolume',
      'vocStatictics',
      'sortedProducts',
      'sort',
      'sortOptions',
      'activeVoCView',
      'showText'
    ]),
    ...mapState('user', [
      'userDetails'
    ]),
    watchable () {
      return JSON.stringify(this.selectedSourceList) + JSON.stringify(this.selectedIndustries) + JSON.stringify(this.selectedCompanies) + JSON.stringify(this.selectedProducts) + JSON.stringify(this.ActiveVocTimePeriod) + JSON.stringify(this.sort)
    }
  },
  watch: {
    watchable () {
      if (this.watchable.length) {
        this.getReviewVolume(true)
      }
      if (this.watchable.includes('[]')) {
        this.setNoData(true)
      } else {
        this.setNoData(false)
      }
    }
  },
  created () {
    const localView = localStorage.getItem('VOC_VIEW_TYPE')
    if (localView && localView !== this.activeVoCView) {
      this.updateActiveVoCView(localView)
    }
    this.updateShowText(true)
  },
  mounted () {
    this.getReviewVolume(true)
  },
  beforeDestroy () {
    this.updateSortedProduct([])
  },
  updated () {
    this.updateShowText(true)
  },
  methods: {
    ...mapActions('vocsummary', [
      'getVocVolume',
      'getSortedProducts',
      'updateSort',
      'updateSortOptions',
      'updateActiveVoCView',
      'updateShowText',
      'updateSortedProduct'
    ]),
    /** This method changes the view */
    changeView (data) {
      this.updateActiveVoCView(data)
      this.updateEvent({
        userId: this.userDetails.userId,
        event: 'click',
        label: analyticsEvents.changeView,
        pageUrl: window.location.href
      })
    },
    /**
     * This method is used to update the sorting for the drivers. It is only used in the table views
     * @param data {Object} - eg. { type: 'review_id', orderBy: 'asc' }
     * @public
     */
    onChangeSelection (data) {
      this.updateSort(data)
    },
    setNoData (value) {
      this.isNoData = value
    },
    /**
     * This method gets the data required to render the VocCards on this page. If sufficient data is not available, the *NoData* container is rendered instead.
     * @public
     */
    async getReviewVolume (loadVolume) {
      if (
        this.selectedSourceList.length &&
        this.selectedProducts.length &&
        this.subCategories.length &&
        this.selectedIndustries.length &&
        this.selectedCompanies.length &&
        this.ActiveVocTimePeriod &&
        this.ActiveVocTimePeriod.group_by
      ) {
        const data = {
          source: this.selectedSourceList.map((item) => item.id),
          products: this.selectedProducts,
          categories: this.subCategories,
          industries: this.selectedIndustries.map((item) => item.id),
          companies: this.selectedCompanies.map((item) => item.id),
          clientId: this.userDetails.clientId,
          startTime: this.ActiveVocTimePeriod.range[0],
          endTime: this.ActiveVocTimePeriod.range[1],
          groupBy: this.ActiveVocTimePeriod.group_by,
          product: this.product,
          sort: this.sort,
          subscriptionId: this.selectedCategories.subscriptionId,
          // requestingModuleId: this.userDetails.modules.filter(m => m.module_name === 'voc')[0].module_id
          requestingModuleId: this.userDetails.modules.find(m => m.module_name === 'voc').module_id
        }
        this.getSortedProducts(data)
        if (loadVolume) {
          const res = await this.getVocVolume(data)
          // this.vocData = res
        }
        /**
         * This event is used to signify that the minimum data needed to render the view was successfully retrieved from the database.
         */
        // this.$emit('data')
      } else {
        // this.isNoData = true
        /**
         * This event is triggered when the minimum data needed to render the view is not available.
         */
        // this.$emit('noData')
      }
    }
  }
}
</script>
<style lang="scss" src="./VocSummary.scss" scoped/>
