<template>
  <!-- <span> -->
    <svg
      :id="'id' + productName.replace(/[^a-zA-Z0-9]/g, '')"
      class="barchart"></svg>
  <!-- </span> -->
</template>
<script>
import { mapState, mapActions } from 'vuex'
import tooltip from '../../util/tooltip'
import moment from 'moment'
import * as d3 from 'd3'
/**
 * This component renders a barchart that shows the monthly distribution of review volume across a selected time period, and the average ratings of reviews for each month
 */
export default {
  name: 'Barchart',
  props: {
    /** The id of each chart */
    id: Number,
    /** The average rating */
    avgRating: Number,
    /** The data used to generate the barchart */
    chartData: {
      type: Array,
      default: null
    },
    /** The name of the product for which the chart is being generated */
    productName: {
      type: String
    },
    /** The id of the product for which the chart is being generated */
    productId: {
      type: Number
    }
  },
  data () {
    return {
      lineData: [],
      data: [],
      observer: null
    }
  },
  computed: {
    ...mapState('filters', [
      'selectedSourceList',
      'selectedProducts',
      'selectedCategories',
      'ActiveVocTimePeriod'
    ]),
    ...mapState('vocsummary', ['vocStatictics', 'showText'])
  },
  watch: {
    vocStatictics () {
      this.prepareData()
    },
    chartData () {
      this.prepareData()
    },
    showText () {
      this.prepareData()
    }
  },
  mounted () {
    this.resizeWindow()
    if (this.chartData.length) {
      this.prepareData()
    }
  },
  beforeDestroy () {
    if (this.observer) this.observer.disconnect()
  },
  methods: {
    ...mapActions('filters', [
      'updateSelectedProducts',
      'updateActiveVocTimePeriod'
    ]),
    ...mapActions('vocsummary', ['updateShowText']),
    /**
     * This method utilizes a resizeObserver to dynamically adjust the width of the chart when the width of it's parent element changes
     * @public
     */
    resizeWindow () {
      this.observer = new ResizeObserver(() => {
        if (this.chartData) {
          if (d3.select('#id' + this.productName.replace(/[^a-zA-Z0-9]/g, '')).node() != null) { this.prepareData() }
        }
      })
      this.observer.observe(d3.select('#id' + this.productName.replace(/[^a-zA-Z0-9]/g, '')).node().parentNode.parentNode)
    },
    /**
     * This method prepares the data that will be passed into the *generateBarChart()* method depending on the time granularity selected by the user (month/quarter)
     * @public
     */
    prepareData () {
      this.data = this.chartData.map((d) => {
        let chartDate = null
        if (this.ActiveVocTimePeriod.group_by === 'month') {
          chartDate = moment(`${d.month}-${d.year_of_review}`, 'M-YYYY').format('MMM \'YY')
        } else {
          chartDate = 'Q' + d.month + ' ' + moment(d.year_of_review, 'YYYY').format('\'YY')
        }
        return {
          month: chartDate,
          value: d.review_volume,
          tooltipDate: chartDate,
          tooltipCsatRating: d.csat_rating ? Number(d.csat_rating.toFixed(1)) : 'N/A',
          tooltipReviewCount: d.review_volume,
          year_of_review: d.year_of_review
        }
      })

      this.lineData = this.chartData.map((d, i) => {
        let lineDate = null
        if (this.ActiveVocTimePeriod.group_by === 'month') {
          lineDate = moment(`${d.month}-${d.year_of_review}`, 'M-YYYY').format('MMM \'YY')
        } else {
          lineDate = 'Q' + d.month + ' ' + moment(d.year_of_review, 'YYYY').format('\'YY')
        }
        return {
          month: lineDate,
          value: d.csat_rating ? Number(d.csat_rating.toFixed(1)) : null,
          tooltipDate: lineDate,
          tooltipCsatRating: d.csat_rating ? Number(d.csat_rating.toFixed(1)) : 'N/A',
          tooltipReviewCount: d.review_volume,
          year_of_review: d.year_of_review
        }
      })
      d3.selectAll(
        '#id' + this.productName.replace(/[^a-zA-Z0-9]/g, '') + ' > *'
      ).remove()

      this.generateBarChart()
    },
    /** This method renders the chart
     * @public
     */
    generateBarChart () {
      const temp = []
      const startDate = moment(this.ActiveVocTimePeriod.range[0])
      const endDate = moment(this.ActiveVocTimePeriod.range[1])

      const selectionDates = []
      // endDate.subtract(1, 'month')

      const month = moment(startDate)
      selectionDates.push(month.format('YYYY-MM-DD'))
      // eslint-disable-next-line no-unmodified-loop-condition
      while (month.format('YYYY-MM') < endDate.format('YYYY-MM')) {
        month.add(1, 'month')
        selectionDates.push(month.format('YYYY-MM-DD'))
      }
      if (this.ActiveVocTimePeriod.group_by !== 'month') {
        temp.push(
          ...selectionDates.map((d) =>
            moment(moment(d).year() + ' ' + moment(d).quarter(), 'YYYY M').format(
              'YYYY-MM-DD'
            )
          )
        )
      } else {
        temp.push(
          ...selectionDates.map((d) =>
            moment(d).format(
              'YYYY-MM-DD'
            )
          )
        )
      }

      const sortedDomain = Array.from(new Set(temp)).sort()
      const width = d3.select('.csat-trend').node().getBoundingClientRect().width
      const height = 85
      const margin = 20
      let circleRadius = 6
      const VocTimePeriod = this.ActiveVocTimePeriod.group_by
      const svg = d3
        .select('#id' + this.productName.replace(/[^a-zA-Z0-9]/g, ''))
        .attr('width', width)
        .attr('height', height + margin)

      const data = this.data
      const lineData = this.lineData
      const xScale = d3
        .scaleBand()
        .range([8, width - margin - 8])
        .paddingInner(0.2)
      const yScale = d3.scaleLinear().range([height, margin])
      const productName = this.productName

      const g = svg
        .append('g')
        .attr('transform', 'translate(' + margin / 2 + ',' + margin + ')')
      xScale.domain(
        sortedDomain.map(function (d) {
          let domainDate = null
          if (VocTimePeriod === 'month') {
            domainDate = moment(d, 'YYYY-MM-DD').format('MMM \'YY')
          } else {
            domainDate = 'Q' + moment(d, 'YYYY-MM-DD').format('M \'YY')
          }
          return domainDate
        })
      )
      yScale.domain([
        0,
        d3.max(data, function (d) {
          return +d.value
        })
      ])

      g.selectAll('.tick text')
        .attr('fill', 'var(--voc-bar-label-color)')

      g.append('g')
        .attr('id', 'barxAxis')
        .attr('transform', 'translate(0,' + (height - margin - 5) + ')')
        .attr('class', 'voc-custom-domain')
        .call(d3.axisBottom(xScale).tickSizeOuter(0))
        .call((g) => g.selectAll('.tick line').remove())

      g
        .selectAll('.bar')
        .data(data)
        .enter()
        .append('g')
        .attr('class', 'vocbar-group')
        .append('rect')
        .attr('class', 'vocbar')
        .attr('x', function (d) {
          return xScale(d.month)
        })
        .attr('y', function (d) {
          return yScale(d.value) - margin - 5
        })
        .attr('rx', 1)
        .attr('width', xScale.bandwidth())
        .attr('height', function (d) {
          return height - yScale(d.value)
        })
        .attr('fill', 'var(--voc-bar-background-color)')
        .attr('fill-opacity', 1)
        .on('mouseover', (event, d) => tooltipFun(event, d, 'in'))
        .on('mousemove', (event, d) => tooltipFun(event, d, 'in'))
        .on('mouseout', (event, d) => tooltipFun(event, d, 'out'))
        .on('click', (event, d) => drillDown(event, d, 'out'))

      function tooltipFun (event, d, type) {
        let data = {}
        switch (type) {
          case 'in':
            // eslint-disable-next-line no-case-declarations
            const format = VocTimePeriod === 'month' ? 'Month' : 'Quarter'
            data = {
              [format]: d.tooltipDate,
              'Review volume': d.tooltipReviewCount,
              'Avg. rating': d.tooltipCsatRating
            }
            break
        }
        tooltip(event, data, type)
      }

      const drillDown = (event, d, type) => {
        const output = this.selectedProducts.filter(({ name }) => name === this.productName)
        let range = []
        if (this.ActiveVocTimePeriod.group_by === 'quarter') {
          range = [moment(d.month, 'Q \'YY').startOf('quarter').format('YYYY-MM-DD'), moment(d.month, 'Q \'YY').endOf('quarter').format('YYYY-MM-DD')]
        } else {
          range = [moment(d.month, 'MMM \'YY').startOf('month').format('YYYY-MM-DD'), moment(d.month, 'MMM \'YY').endOf('month').format('YYYY-MM-DD')]
        }
        const timeObject = {}
        timeObject.group_by = this.ActiveVocTimePeriod.group_by
        timeObject.range = range
        this.updateActiveVocTimePeriod(timeObject)
        if (output.length) {
          this.updateSelectedProducts([output[0]])
        }
        tooltipFun(event, d, 'out')
        this.$router.push({ name: 'comments' })
      }

      const xRange = d3
        .scaleBand()
        .range([8, width - margin - 8])
        .paddingInner(0.2)
        .domain(
          sortedDomain.map(function (d) {
            let domainDate = null
            if (VocTimePeriod === 'month') {
              domainDate = moment(d, 'YYYY-MM-DD').format('MMM \'YY')
            } else {
              domainDate = 'Q' + moment(d, 'YYYY-MM-DD').format('M \'YY')
            }
            return domainDate
          // return VocTimePeriod === 'month'
          //   ? moment(d, 'YYYY MM').format('MMM \'YY')
          //   : 'Q' + (new Date(d).getMonth() + 1) + ' ' + moment(new Date(d).getFullYear(), 'YYYY').format('\'YY') //
          })
        )
      const yRange = d3
        .scaleLinear()
        .range([height - margin - circleRadius, margin])
        .domain([0, 5])

      let totalWidth = 0
      g.select('#barxAxis')
        .selectAll('g')
        .each(function (d, index, data) {
          const temp = d3
            .select(this)
            .select('text')
            .node()
            .getBoundingClientRect().width
          totalWidth += temp
        })
      if (totalWidth + margin > width) {
        g.select('#barxAxis')
          .selectAll('g')
          .each(function (d, index, data) {
            if (!(index === 0 || index === data.length - 1)) {
              d3.select(this).select('text').remove()
            }
          })
      }

      // const line = d3
      //   .line()
      //   // .defined((d, i) => d.value)
      //   .x(function (d, i) {
      //     return xRange(d.month)
      //   })
      //   .y(function (d) {
      //     return yRange(d.value)
      //   })
      //   .curve(d3.curveLinear)

      let counter = 0
      let start = null
      let end = 0

      lineData.forEach((d, i) => {
        // console.log(i, d.tooltipCsatRating, productName)
        if (!isNaN(d.tooltipCsatRating)) {
          start = start === null ? i : start
          end = i
        }
      })

      const line = d3.line()
        .defined((d, i) => {
          // console.log(start, end, i, productName)
          return (i >= start && i <= end)
        })
        .x(function (d, i) {
          return xRange(d.month)
        })
        .y(function (d, i) {
          // console.log(data)
          if (!isNaN(d.tooltipCsatRating)) {
            counter = i
            return yRange(d.tooltipCsatRating)
          } else {
            return yRange(Number(lineData[counter].tooltipCsatRating) ? Number(lineData[counter].tooltipCsatRating) : 0)
          }
        })
        .curve(d3.curveLinear)

      g.append('g')
        .attr(
          'transform',
          'translate(' + xScale.bandwidth() / 2 + ',' + 0 + ')'
        )
        .append('path')
        .datum(lineData)
        .attr('class', 'line') // Assign a class for styling
        .attr('fill', 'none')
        .attr('stroke', 'var(--voc-bar-line-color)')
        .attr('stroke-width', 2)
        .attr('d', line) // Calls the line generator

      circleRadius =
        Number(g.select('rect').attr('width') * 0.5).toFixed(0) > 12
          ? 5
          : Number(g.select('rect').attr('width') * 0.5).toFixed(0) / 2
      const node = g.selectAll('.vocbar-group').data(lineData).join()
      node
        .append('circle')
        .attr('class', 'dot')
        .attr('cx', function (d, i) {
          return xRange(d.month) + xScale.bandwidth() / 2
        })
        .attr('cy', function (d) {
          return yRange(d.value)
        })
        .attr('stroke', 'none')
        .attr('fill', function (d) {
          return d.value ? 'var(--voc-bar-circle-color)' : 'transparent'
        })
        .attr('r', circleRadius)
        .style('pointer-events', d => d.value ? 'all' : 'none')
        .on('mouseover', (event, d) => tooltipFun(event, d, 'in'))
        .on('mousemove', (event, d) => tooltipFun(event, d, 'in'))
        .on('mouseout', (event, d) => tooltipFun(event, d, 'out'))
        .on('click', (event, d) => drillDown(event, d, 'out'))

      if (this.showText) {
        g.selectAll('.vocbar-group')
          .data(data)
          .append('text')
          .attr('class', 'vocbar-val')
          .text(function (d) {
            return d.value
          })
          .attr('x', function (d) {
            return xScale(d.month) + xScale.bandwidth() / 2
          })
          .attr('y', function (d) {
            const rectHeight = yScale(d.value) - margin - 5
            const dotYPosition = Number(d3.select(this).node().previousSibling.getAttribute('cy'))
            if (rectHeight < dotYPosition || (rectHeight - dotYPosition > (width / 47.5) + 10)) {
              return rectHeight - 5
            } else {
              return dotYPosition - 10
            }
          })
          .attr('fill', 'var(--voc-bar-text-color)')
          .attr('font-size', `clamp(9px, ${width / 47.5}px, 11px)`)
          .attr('font-weight', 700)
          .attr('text-anchor', 'middle')

        let maxTotalWidth = 0
        svg.selectAll('.vocbar-val').each(function (d, index, data) {
          const currentWidth = d3
            .select(this)
            .node()
            .getBoundingClientRect().width
          maxTotalWidth = maxTotalWidth > currentWidth ? maxTotalWidth : currentWidth
        })
        if (maxTotalWidth > xScale.bandwidth()) {
          this.updateShowText(false)
        }
      }
    }
  }
}
</script>
<style>
  .voc-custom-domain .tick text {
    font-size: 11px;
    font-family: Quicksand, sans-serif;
    font-weight: 400;
    stroke: none;
    fill: var(--voc-bar-text-color);
  }
  .voc-custom-domain .domain {
    /* display: none; */
    stroke: var(--voc-bar-background-color);
  }

  :where(.vocbar, .dot):hover {
    cursor: pointer;
  }
</style>
