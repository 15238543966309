<template>
  <tr v-if="vocVolume">
    <td class="header">
      <div class="serial">
        <div class="product-logo">
          <img
            v-if="(user_preference_mode=='dark' && product.properties && product.properties.logo_dark) || (user_preference_mode=='light' && product.properties && product.properties.logo_light)"
            :src="
          user_preference_mode=='dark'
            ? product.properties.logo_dark
            : product.properties.logo_light
        "
            :alt="product.name"
            :title="product.name" />
          <span
            v-else
            class="product-name">{{product.name}}</span>
        </div>
      </div>
    </td>
    <td>
      <div
        class="review-volume"
        v-if="(vocVolume && (Number(vocVolume) > 0))">
          {{ vocVolume }}
      </div>
    </td>
    <td>
      <div
        class="rating-data">
        <p v-if="vocData.summaryData.csat_ratings"><span class="rating">{{vocData.summaryData.csat_ratings}}</span> out of 5</p>
        <p v-else><span class="rating">N/A</span></p>
        <div class="dissatisfied"><span class="rating-distribution">{{dissatisfiedDistribution}}%</span>&#9733;&#9733;&#9734;&#9734;&#9734; <span class="rating-label">1-2 stars</span></div>
        <div class="neutral"><span class="rating-distribution">{{neutralDistribution}}%</span>&#9733;&#9733;&#9733;&#9734;&#9734; <span class="rating-label">3 stars</span></div>
        <div class="satisfied"><span class="rating-distribution">{{satisfiedDistribution}}%</span>&#9733;&#9733;&#9733;&#9733;&#9733; <span class="rating-label">4-5 stars</span></div>
        <div class="null-value"><span class="rating-distribution">{{nullDistribution}}%</span>&#9734;&#9734;&#9734;&#9734;&#9734; <span class="rating-label">No Rating</span></div>
      </div>
    </td>
    <td>
      <div
        class="recommendation-score">
        <p>Likely to Recommend</p>
        <div>{{vocData.summaryData.recommendation_score ? Math.round((vocData.summaryData.recommendation_score/vocData.summaryData.total)*100) +'% ' :'N/A' }}</div>
      </div>
    </td>
    <td>
      <div
        class="csat-trend" ref="barchartContainer"
        v-if="vocData.trendData && vocData.trendData.length">
        <bar-chart
          :chartData="vocData.trendData"
          :key="csatRatings ? Number(csatRatings.toFixed(2)) : id"
          :id="id"
          :avgRating="csatRatings"
          :productName="product.name"
          :productId="product.id" />
      </div>
    </td>
  </tr>
  <tr v-else>
    <td class="header">
      <div class="serial">
        <div class="product-logo">
          <img
            v-if="(user_preference_mode=='dark' && product.properties && product.properties.logo_dark) || (user_preference_mode=='light' && product.properties && product.properties.logo_light)"
            :src="
          user_preference_mode=='dark'
            ? product.properties.logo_dark
            : product.properties.logo_light
        "
            :alt="product.name"
            :title="product.name" />
          <span
            v-else
            class="product-name">{{product.name}}</span>
        </div>
      </div>
    </td>
    <td colspan="4" class="no-data">
      <div
        class="no-review"
        v-if="isEmpty">
        <b-icon class="nodata-icon" icon="information-outline"></b-icon>
        No reviews for {{product.name}} for the selection
      </div>
    </td>
  </tr>
</template>

<script>
import BarChart from './Barchart.vue'
import { mapActions, mapState } from 'vuex'

/**
 * This component is used to render the VocSummary page info in a list view
 */
export default {
  components: {
    BarChart
  },
  props: {
    index: {
      type: Number
    },
    /**
     * ID of each row
     */
    id: {
      type: Number,
      default: 1
    },
    /**
     * Product whose information is displayed
     */
    product: {
      type: Object
    }
  },
  data () {
    return {
      vocData: {},
      isEmpty: false,
      barchartContainerWidth: null
    }
  },
  computed: {
    ...mapState('filters', [
      'productList',
      'selectedSourceList',
      'selectedProducts',
      'subCategories',
      'ActiveVocTimePeriod',
      'categoryList',
      'loading',
      'selectedIndustries',
      'selectedCompanies',
      'sortedProducts',
      'selectedCategories'
    ]),
    ...mapState('common', ['user_preference_mode', 'isCollapsed']),
    ...mapState('user', ['userDetails']),
    ...mapState('vocsummary', [
      'vocStatictics',
      'maxReviewVolume'
    ]),
    csatRatings () {
      if (this.vocData && this.vocData.summaryData) {
        return this.vocData.summaryData.csat_ratings
      } else {
        return 0
      }
    },
    vocVolume () {
      if (this.vocData && this.vocData.summaryData) {
        return this.vocData.summaryData.review_volume
      } else {
        return 0
      }
    },
    dissatisfiedDistribution () {
      return Number((this.vocData.summaryData.dissatisfied_count / this.vocData.summaryData.review_volume) * 100).toFixed(1)
    },
    neutralDistribution () {
      return Number((this.vocData.summaryData.nutral_count / this.vocData.summaryData.review_volume) * 100).toFixed(1)
    },
    satisfiedDistribution () {
      return Number((this.vocData.summaryData.satisfied_count / this.vocData.summaryData.review_volume) * 100).toFixed(1)
    },
    nullDistribution () {
      return Number((this.vocData.summaryData.null_count / this.vocData.summaryData.review_volume) * 100).toFixed(1)
    }
  },
  watch: {
    product () {
      this.vocData = {}
      this.getStatistics()
    },
    isCollapsed () {
      if (!this.isCollapsed) {
        if (this.$refs.barchartContainer) {
          this.$refs.barchartContainer.style.setProperty('--list-view-barchart-width', this.barchartContainerWidth + 'px')
        }
      } else {
        if (this.$refs.barchartContainer) {
          this.$refs.barchartContainer.style.setProperty('--list-view-barchart-width', '')
        }
      }
    }
  },
  mounted () {
    this.isEmpty = false
    this.vocData = {}
    this.getStatistics()
  },
  updated () {
    if (this.$refs.barchartContainer) {
      this.barchartContainerWidth = this.$refs.barchartContainer.clientWidth
    }
  },
  methods: {
    ...mapActions('vocsummary', [
      'getVocStatistics'
    ]),
    /**
     * This method gets the data required to render the information for the VocSummary view
     */
    async getStatistics () {
      if (
        this.selectedSourceList.length &&
        this.selectedProducts.length &&
        this.subCategories.length &&
        this.selectedIndustries.length &&
        this.selectedCompanies.length &&
        this.ActiveVocTimePeriod &&
        this.ActiveVocTimePeriod.range[0] &&
        this.ActiveVocTimePeriod.range[1] &&
        this.ActiveVocTimePeriod.group_by
      ) {
        const data = {
          source: this.selectedSourceList.map((item) => item.id),
          // product: this.selectedProducts.filter((obj) => Number(obj.id) === this.product.id)[0],
          product: this.selectedProducts.find((obj) => Number(obj.id) === this.product.id),
          categories: this.subCategories,
          industries: this.selectedIndustries.map((item) => item.id),
          companies: this.selectedCompanies.map((item) => item.id),
          clientId: this.userDetails.clientId,
          startTime: this.ActiveVocTimePeriod.range[0],
          endTime: this.ActiveVocTimePeriod.range[1],
          groupBy: this.ActiveVocTimePeriod.group_by,
          subscriptionId: this.selectedCategories.subscriptionId,
          // requestingModuleId: this.userDetails.modules.filter(m => m.module_name === 'voc')[0].module_id
          requestingModuleId: this.userDetails.modules.find(m => m.module_name === 'voc').module_id
        }
        const res = await this.getVocStatistics(data)
        if (res.trendData && res.trendData.length) {
          this.isEmpty = false
        } else {
          this.isEmpty = true
        }
        this.vocData = res
        /**
         * This event is used to signify that the minimum data needed to render the view was successfully retrieved from the database.
         */
        this.$emit('data')
      } else {
        this.isNoData = true
        /**
         * This event is triggered when the minimum data needed to render the view is not available.
         */
        this.$emit('noData')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.serial {
  display: inline-flex;
  flex-direction: row;
  align-items: center;
}
.csat-trend {
  max-width: var(--list-view-barchart-width);
  width: 100%;
}
.voc-row-card {
  align-items: center;
  .product-logo {
    img {
      width: 120px;
      height: 49px;
      object-fit: contain;
      color: var(--chart-top-axis-color);
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
    .product-name {
      color: var(--chart-top-axis-color);
      align-self: center;
      width: 60%;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
    .fainted-logo {
      opacity: 1;
    }
  }
  .fainted-logo {
    opacity: 0.4;
  }
}
.review-volume {
  text-align: center;
  font-family: Quicksand, sans-serif;
  font-weight: 600;
  font-size: 30px;
  line-height: 1.19;
  color: var(--voc-list-review-volume);
  opacity: 1;
}
.recommendation-score {
  background: rgba(245, 119, 119, 0.2);
  background: var(--recommendation-score-background-color);
  text-align: left;
  padding: 10px 40px;
  display: flex;
  width: max-content;
  flex-direction: column;
  margin-top: auto;
  color: var(--recommendation-score-text-color);
  margin: 0 auto;
  p {
    display: flex;
    opacity: 1;
    width: 100%;
    align-items: center;
    font-weight: 500;
  }
  div {
    opacity: 1;
    color: var(--recommendation-score-font-color);
    font-weight: 700;
    margin: 0 auto;
    display: flex;
    font-size: 30px;
  }
}
.no-review {
  padding: 20px 0px 15px 0px;
  color: var(--secondary-text-color);
  font-family: Quicksand;
  font-weight: normal;
  font-size: 16px;
  line-height: 1.19;
  opacity: 0.4;
  display: flex;
  align-items: center;
  .nodata-icon {
    padding-right: 20px;
  }
}
.rating-data {
  color: var(--voc-list-rating-label);
}
.rating {
  color: var(--voc-list-rating);
  font-weight: 600;
  font-size: 30px;
}
.dissatisfied, .neutral, .satisfied, .null-value {
  color: #FEC007;
  font-size: 16px;
  display: flex;
  justify-items: stretch;
  .rating-distribution {
    display: inline-flex;
    justify-content: end;
    padding-right: 5px;
    width: 50px;
    font-weight: 600;
    color: var(--voc-list-rating-distribution);
  }
  .rating-label {
    color: var(--voc-list-rating-label);
    display: inline-flex;
    width: 33.33%;
    padding-left: 5px;
  }
}
table {
  .header {
    left: 0;
    position: -webkit-sticky;
    position: sticky;
    z-index: 9999;
  }
  tbody tr {
    position: relative;
    border-bottom: solid 1.25px var(--voc-split-border-color);
    td {
      padding: 0px 10px;
      vertical-align: middle;
      min-width: 200px;
    }
    td:first-child {
      left: 0px;
      text-align: left;
      width: 200px;
      z-index: 9;
      background: var(--primary);
      box-shadow: -1px 0px 0px 0px var(--voc-split-border-color),
        1px 0px 0px 0px var(--voc-split-border-color); // display: flex;
      height: 176px;
      .product-logo {
        width: 160px;
        height: 49px;
      }
    }
    td:nth-child(2) {
      min-width: 100px;
    }
    td:nth-child(3) {
      text-align: left;
      padding-left: 30px;
      min-width: 280px;
    }
    td:nth-child(4) {
      min-width: 150px;
    }
    td:nth-child(5) {
      min-width: 350px;
      display: flex;
      align-items: center;
      width: 100%;
      height: 100%;
    }
    .no-data {
      padding-left: 50px;
    }
  }
}
.header {
  left: 0;
  position: -webkit-sticky;
  position: sticky;
}

@media screen and (min-width: 1700px) {
  .serial {
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    padding-left: 20px;
  }
  .voc-row-card {
    align-items: center;
  }
  .review-volume {
    text-align: center;
    font-family: Quicksand;
    font-weight: 600;
    font-size: 36px;
    line-height: 1.19;
    color: var(--voc-list-review-volume);
    opacity: 1;
  }
  .recommendation-score {
    background: rgba(245, 119, 119, 0.2);
    background: var(--recommendation-score-background-color);
    text-align: left;
    padding: 8px 50px;
    display: flex;
    width: max-content;
    flex-direction: column;
    margin-top: auto;
    color: var(--recommendation-score-text-color);
    margin: 0 auto;
    p {
      display: flex;
      opacity: 1;
      width: 100%;
      align-items: center;
      font-weight: 500;
    }
    div {
      opacity: 1;
      color: var(--recommendation-score-font-color);
      font-weight: 700;
      margin: 0 auto;
      display: flex;
      font-size: 40px;
    }
  }
  .no-review {
    padding: 20px 0px 15px 0px;
    font-family: Quicksand;
    font-weight: normal;
    font-size: 16px;
    line-height: 1.19;
    color: #4993ff;
    opacity: 1;
  }
  .no-review {
    color: var(--secondary-text-color);
    opacity: 0.4;
    // vertical-align: middle;
    display: flex;
    align-items: center;
    .nodata-icon {
      padding-right: 20px;
    }
  }
  .rating-data {
    color: var(--voc-list-rating-label);
  }
  .rating {
    color: var(--voc-list-rating);
    font-weight: 600;
    font-size: 36px;
  }
  .dissatisfied, .neutral, .satisfied {
    color: #FEC007;
    font-size: 16px;
    display: flex;
    justify-items: stretch;
    .rating-distribution {
      display: inline-flex;
      justify-content: end;
      padding-right: 5px;
      width: 50px;
      font-weight: 600;
      color: var(--voc-list-rating-distribution);
    }
    .rating-label {
      color: var(--voc-list-rating-label);
      display: inline-flex;
      width: 33.33%;
      padding-left: 5px;
    }
  }
  table {
    tbody tr {
      td:nth-child(3) {
        text-align: left;
      }
      td:nth-child(5) {
        display: flex;
      }
    }
  }
}
</style>
